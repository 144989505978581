<template>
  <a
    href="/clients/cart"
    class="relative flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 gap-3">
    <i class="fa-regular fa-cart-shopping text-xl fa-fw" />
    <div class="js-secondary-nav-title flex items-center justify-between flex-1">
      <span>Cart</span>
      <UiBadge class="inline-flex items-center justify-center w-6 h-6 font-bold">
        <span v-if="cart.isSyncing.value"><i class="fa fa-spinner fa-spin" /></span>
        <span v-else>{{ cart.itemsCount }}</span>
      </UiBadge>
    </div>
  </a>
</template>

<script setup>
import { UiBadge } from '@ui/badge'
import useCart from '@/vue/composables/use_cart'

const cart = useCart()
cart.getCart()
</script>
