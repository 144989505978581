import VueBaseController from '../vue_base'

export class MessagesController extends VueBaseController { // eslint-disable-line import/prefer-default-export
  static get COMPONENT() { return () => import('@/vue/components/notifications/notification_wrapper.vue') }

  connect() {
    super.connect({
      isClient: true,
      notificationsNamespace: 'messages'
    })
  }
}

export class ApprovalsController extends VueBaseController { // eslint-disable-line import/prefer-default-export
  static get COMPONENT() { return () => import('@/vue/components/notifications/notification_wrapper.vue') }

  connect() {
    super.connect({
      isClient: true,
      notificationsNamespace: 'approvals'
    })
  }
}
